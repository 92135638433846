export default function SubmitingForm(){


    return (
        <div className="overlay">
            <div className="overlay-content">
                <i className="bx bx-loader bx-spin fs-1 align-middle me-2 d-block" />
                Procesando solicitud
            </div>
        </div>
        
    )
}